import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FilterDateDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { deriveUnion, identity } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import {
  GetAccountTransactionsDocument,
  OrderByDirection,
  PaymentProduct,
  TransactionListFragment,
  TransactionStatus,
  TransactionTypeEnum,
  TransactionsOrderByField,
  TransactionsOrderByInput,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, locale, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import { transactionTypes } from "../utils/transactions";
import {
  isAfterUpdatedAtSelectable,
  isBeforeUpdatedAtSelectable,
  validateAfterUpdatedAt,
  validateBeforeUpdatedAt,
} from "../utils/validations";
import { AccountDetailTransactionList, ExtraInfo } from "./AccountDetailTransactionList";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

type Props = {
  accountId: string;
  params: RouteParams["AccountDetailTransactions"];
};

const paymentProducts = deriveUnion<PaymentProduct>({
  Card: true,
  Check: true,
  InternalCreditTransfer: true,
  InternationalCreditTransfer: true,
  InternalDirectDebit: true,
  SEPACreditTransfer: true,
  SEPADirectDebit: true,
  Fees: true,
});

const statuses = ["Booked", "Canceled", "Pending", "Rejected", "Released", "Upcoming"] as const;

const isAfterUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transaction.filters.isAfterUpdatedAt.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  isSelectable: isAfterUpdatedAtSelectable,
  validate: validateAfterUpdatedAt,
};

const isBeforeUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transaction.filters.isBeforeUpdatedAt.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  isSelectable: isBeforeUpdatedAtSelectable,
  validate: validateBeforeUpdatedAt,
};

const paymentProductFilter: FilterCheckboxDef<PaymentProduct> = {
  type: "checkbox",
  label: t("transaction.filters.paymentProduct.label"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: paymentProducts.array.map(value => ({ label: value, value })),
};

const statusFilter: FilterCheckboxDef<TransactionStatus> = {
  type: "checkbox",
  label: t("transaction.filters.status.label"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: statuses.map(value => ({ label: value, value })),
};

const typeFilter: FilterCheckboxDef<TransactionTypeEnum> = {
  type: "checkbox",
  label: t("transaction.filters.type.label"),
  submitText: t("common.filters.apply"),
  checkAllLabel: t("common.filters.all"),
  items: transactionTypes.array.map(value => ({ label: value, value })),
};

const filtersDefinition = {
  isAfterUpdatedAt: isAfterUpdatedAtFilter,
  isBeforeUpdatedAt: isBeforeUpdatedAtFilter,
  paymentProduct: paymentProductFilter,
  status: statusFilter,
  type: typeFilter,
};

type TransactionsFilters = FiltersState<typeof filtersDefinition>;

type EventsFiltersFormProps = {
  filters: TransactionsFilters;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: TransactionsFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const EventsFiltersForm = ({
  filters,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: EventsFiltersFormProps) => {
  const availableFilters: { name: keyof TransactionsFilters; label: string }[] = useMemo(
    () => [
      {
        name: "isAfterUpdatedAt",
        label: t("transaction.filters.isAfterUpdatedAt.label"),
      },
      {
        name: "isBeforeUpdatedAt",
        label: t("transaction.filters.isBeforeUpdatedAt.label"),
      },
      {
        name: "paymentProduct",
        label: t("transaction.filters.paymentProduct.label"),
      },
      {
        name: "status",
        label: t("transaction.filters.status.label"),
      },
      {
        name: "type",
        label: t("transaction.filters.type.label"),
      },
    ],
    [],
  );

  // As per UX request, the fields you select should remain on screen, even if the popover
  // is dismissed and the value unchanged.
  // We also want to preserve the insertion order through the component lifecycle.

  // That's why we maintain the list in a state cell:
  // - The initial state is taken from the URL
  // - When `filters` from URL change, we add the ones not yet tracked at the end of the array
  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([_name, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          title={t("webhook.filters.choose")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={16} />

        <TrackPressable action="Refresh transactions list">
          <LakeButton
            ariaLabel={t("webhook.table.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </View>
  );
};

const PER_PAGE = 20;

export const AccountDetailTransactions = ({ accountId, params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  // The following pattern matching is used to correctly type the values,
  // incorrect values are set as `undefined` (aka. unset).
  const serializedPaymentProduct = JSON.stringify(params.paymentProduct ?? null);
  const serializedStatus = JSON.stringify(params.status ?? null);
  const serializedType = JSON.stringify(params.type ?? null);

  const filters: TransactionsFilters = useMemo(() => {
    const paymentProduct = JSON.parse(serializedPaymentProduct) as string[] | null | undefined;
    const status = JSON.parse(serializedStatus) as string[] | null | undefined;
    const type = JSON.parse(serializedType) as string[] | null | undefined;

    return {
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
      paymentProduct: isNotNullish(paymentProduct)
        ? Array.filterMap(paymentProduct, item =>
            match(item)
              .with(paymentProducts.P, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(status)
        ? Array.filterMap(status, item =>
            match(item)
              .with(...statuses, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,

      type: isNotNullish(type)
        ? Array.filterMap(type, item =>
            transactionTypes.is(item) ? Option.Some(item) : Option.None(),
          )
        : undefined,
    } as const;
  }, [
    params.isAfterUpdatedAt,
    params.isBeforeUpdatedAt,
    serializedPaymentProduct,
    serializedStatus,
    serializedType,
  ]);

  const sortBy: TransactionsOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<TransactionsOrderByField>()
        .with("executionDate", "id", "createdAt", "updatedAt", identity)
        .otherwise(() => "executionDate"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountTransactionsDocument, {
    accountId,
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("AccountDetailTransactions", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, reload, sortBy, params]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<TransactionListFragment>, ExtraInfo>) => (
      <Link
        to={Router.AccountDetailTransactionsDetail({
          projectId,
          projectEnv,
          accountId,
          transactionId: id,
        })}
      />
    ),
    [accountId],
  );

  const transactions = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ account }) => Option.fromNullable(account?.transactions));

  const totalCount = transactions.map(({ totalCount }) => totalCount);
  const pageInfo = transactions.map(({ pageInfo }) => pageInfo);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <EventsFiltersForm
        totalCount={totalCount}
        filters={filters}
        search={search}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("AccountDetailTransactions", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("AccountDetailTransactions", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.account?.transactions}>
            {transactions => (
              <AccountDetailTransactionList
                transactions={transactions?.edges ?? []}
                onEndReached={() => {
                  if (pageInfo.isSome() && pageInfo.get()?.hasNextPage === true) {
                    setVariables({ after: pageInfo.get()?.endCursor ?? undefined });
                  }
                }}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                emptyListTitle={t("account.transactions.empty", {
                  name: data.account?.name ?? accountId,
                })}
                getRowLink={getRowLink}
                hasSearchOrFilters={hasSearchOrFilters}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

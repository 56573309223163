import { Array, Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { capitalize } from "@swan-io/lake/src/utils/string";
import {
  Document,
  SupportingDocumentCollection,
} from "@swan-io/shared-business/src/components/SupportingDocumentCollection";
import { StyleSheet, View } from "react-native";
import { match, P } from "ts-pattern";
import {
  GenerateSupportingDocumentUploadUrlDocument,
  SupportingDocumentCollectMode,
  SupportingDocumentPurposeEnum,
  SupportingDocumentsCollectionConnectionFragment,
} from "../graphql/partner";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  list: {
    listStyleType: "disc",
  },
  listItem: {
    display: "list-item",
    listStyleType: "disc",
    marginLeft: "1em",
  },
});

type Props = {
  supportingDocumentCollections: SupportingDocumentsCollectionConnectionFragment;
  supportingDocumentCollectMode: SupportingDocumentCollectMode | undefined;
};

export const TransactionsDetailSupportingDocumentCollections = ({
  supportingDocumentCollections: { edges, totalCount },
  supportingDocumentCollectMode,
}: Props) => {
  const [generateSupportingDocument] = useMutation(GenerateSupportingDocumentUploadUrlDocument);

  return totalCount === 0 ? (
    <Box alignItems="center" justifyContent="center" grow={1}>
      <EmptyView
        icon="lake-inbox-empty"
        title={t("transaction.supportingDocumentCollectionEmpty")}
      />
    </Box>
  ) : (
    <LakeScrollView>
      {edges.map(
        ({ node: { id, statusInfo, supportingDocuments, requiredSupportingDocumentPurposes } }) => {
          const docs = Array.filterMap(supportingDocuments, document =>
            match(document)
              .returnType<Option<Document<SupportingDocumentPurposeEnum>>>()
              .with(P.nullish, () => Option.None())
              .with({ statusInfo: { __typename: "SupportingDocumentNotUploadedStatusInfo" } }, () =>
                Option.None(),
              )
              .with(
                {
                  statusInfo: {
                    __typename: "SupportingDocumentWaitingForUploadStatusInfo",
                  },
                },
                () => Option.None(),
              )
              .with(
                { statusInfo: { __typename: "SupportingDocumentValidatedStatusInfo" } },
                document =>
                  Option.Some({
                    purpose: document.supportingDocumentPurpose,
                    file: {
                      id: document.id,
                      name: document.statusInfo.filename,
                      url: document.statusInfo.downloadUrl,
                      statusInfo: { status: "Validated" },
                    },
                  }),
              )
              .with(
                { statusInfo: { __typename: "SupportingDocumentRefusedStatusInfo" } },
                document =>
                  Option.Some({
                    purpose: document.supportingDocumentPurpose,
                    file: {
                      id: document.id,
                      name: document.statusInfo.filename,
                      url: document.statusInfo.downloadUrl,
                      statusInfo: { status: "Refused", reason: document.statusInfo.reason },
                    },
                  }),
              )
              .with(
                { statusInfo: { __typename: "SupportingDocumentUploadedStatusInfo" } },
                document =>
                  Option.Some({
                    purpose: document.supportingDocumentPurpose,
                    file: {
                      id: document.id,
                      name: document.statusInfo.filename,
                      url: document.statusInfo.downloadUrl,
                      statusInfo: { status: "Uploaded" },
                    },
                  }),
              )
              .exhaustive(),
          );

          return (
            <TileGrid key={id}>
              <Tile title={t("transaction.supportingDocumentCollection")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("transaction.supportingDocumentCollection.id")}
                    render={() => <LakeText color={colors.gray[900]}>{id}</LakeText>}
                    actions={
                      <LakeCopyButton
                        valueToCopy={id}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("transaction.supportingDocumentCollection.status")}
                    render={() =>
                      match(statusInfo.status)
                        .with("Approved", () => <Tag color="positive">{statusInfo.status}</Tag>)
                        .with("Canceled", () => (
                          <Tag color="gray">
                            {t("supportingDocumentCollection.status.Canceled")}
                          </Tag>
                        ))
                        .with("WaitingForDocument", () => (
                          <Tag color="warning">
                            {t("supportingDocumentCollection.status.WaitingForDocument")}
                          </Tag>
                        ))
                        .with("PendingReview", () => (
                          <Tag color="shakespear">
                            {t("supportingDocumentCollection.status.PendingReview")}
                          </Tag>
                        ))
                        .with("Rejected", () => (
                          <Tag color="negative">
                            {t("supportingDocumentCollection.status.Rejected")}
                          </Tag>
                        ))
                        .exhaustive()
                    }
                  />

                  {requiredSupportingDocumentPurposes.length > 0 &&
                  supportingDocumentCollectMode !== "API" ? (
                    <LakeLabel
                      type="view"
                      label={t(
                        "transaction.supportingDocumentCollection.requestedSupportingDocuments",
                      )}
                      render={() => (
                        <View role="list" style={styles.list}>
                          {requiredSupportingDocumentPurposes.map(purpose => (
                            <View role="listitem" style={styles.listItem} key={purpose.name}>
                              <LakeText color={colors.gray[900]}>
                                {capitalize(
                                  purpose.name
                                    .replace(
                                      /([A-Z])([a-z])/g,
                                      (_, $1, $2) => ` ${String($1).toLowerCase()}${$2}`,
                                    )
                                    .trim(),
                                )}
                              </LakeText>
                            </View>
                          ))}
                        </View>
                      )}
                    />
                  ) : null}
                </ReadOnlyFieldList>
              </Tile>

              <Tile title={t("transaction.supportingDocument")}>
                <SupportingDocumentCollection
                  generateUpload={({
                    fileName,
                    purpose,
                  }: {
                    fileName: string;
                    purpose: SupportingDocumentPurposeEnum;
                  }) =>
                    generateSupportingDocument({
                      input: {
                        filename: fileName,
                        supportingDocumentPurpose: purpose,
                        supportingDocumentCollectionId: id,
                      },
                    })
                      .mapOk(data => data.generateSupportingDocumentUploadUrl)
                      .mapOkToResult(filterRejectionsToResult)
                      .mapOk(({ upload, supportingDocumentId }) => ({
                        upload,
                        id: supportingDocumentId,
                      }))
                  }
                  status={statusInfo.status}
                  showIds={true}
                  documents={docs}
                  requiredDocumentPurposes={requiredSupportingDocumentPurposes.map(
                    item => item.name,
                  )}
                />
              </Tile>
            </TileGrid>
          );
        },
      )}
    </LakeScrollView>
  );
};

import { Dict } from "@swan-io/boxed";
import { InferRoutes, createGroup, createRouter } from "@swan-io/chicane";

export const routes = {
  BaseRoot: "/",
  BasePopupCallback: "/swanpopupcallback",
  BaseStart: "/start",
  BaseLogin: "/login?:redirectTo&:sessionExpired",
  BaseProjects: "/projects",
  BaseSsoProjectPicker: "/sso/projects",

  ...createGroup("Project", "/projects/:projectId?:activate", {
    Area: "/*",
    Root: "?:alreadyInvited",

    ...createGroup("Admin", "/admin", {
      Area: "/*",
      Team: "/team?:new&:editingMembershipId",
    }),
  }),

  ...createGroup("", "/projects/:projectId/:projectEnv{live|sandbox}", {
    ProjectWithEnvArea: "/*",
    ProjectWithEnvRoot: "/",

    DevelopersArea: "/developers/*",
    DevelopersApi: "/developers/api",

    DataArea: "/data/*",

    ...createGroup("User", "/data/users", {
      Area: "/*",
      List: "?:firstName&:lastName&:mobilePhoneNumber&:identificationLevel&:nationalityCCA3&:preferredNotificationChannel&:search&:status{Active|Inactive}",
      Detail: "/:userId",
    }),

    OnboardingArea: "/data/onboardings/*",
    OnboardingRoot: "/data/onboardings?:types[]&:search&:sortBy&:direction&:status[]",

    OnboardingDetailArea: "/data/onboardings/:onboardingId/*",
    OnboardingDetailRoot: "/data/onboardings/:onboardingId",
    OnboardingDetailUltimateBeneficialOwners:
      "/data/onboardings/:onboardingId/ultimate-benificial-owners",
    OnboardingDetailSupportingDocuments:
      "/data/onboardings/:onboardingId/supporting-documents/:supportingDocumentCollectionId",
    OnboardingDetailSupportingDocumentsNew:
      "/data/onboardings/:onboardingId/supporting-documents/:supportingDocumentCollectionId/new",

    ...createGroup("CapitalDeposit", "/data/capital-deposits", {
      Area: "/*",
      List: "?:status[]&:sortBy&:direction&:search",
      ...createGroup("Detail", "/:capitalDepositId", {
        Area: "/*",
        Root: "/",
        ShareholdersList: "/shareholders",
        ...createGroup("Shareholder", "/:shareholderId", {
          Area: "/*",
          Root: "/",
        }),
      }),
    }),

    CardArea: "/data/cards/*",
    CardRoot: "/data/cards?:statuses[]&:types[]&:sortBy&:direction&:search",

    CardDetailArea: "/data/cards/:cardId/*",
    CardDetailRoot: "/data/cards/:cardId",
    CardDetailDigitalCards:
      "/data/cards/:cardId/digital-cards?:status&:walletProviderName&:cardMaskedNumber",
    CardDetailTransactions:
      "/data/cards/:cardId/transactions?:isAfterUpdatedAt&:isBeforeUpdatedAt&:paymentProduct[]&:search&:status[]&:type[]",

    HoldersArea: "/data/account-holders/*",
    HoldersRoot:
      "/data/account-holders?:status[]&:types[]&:verificationStatus[]&:search&:sortBy&:direction",
    HoldersVerificationArea:
      "/data/account-holders/kyc?:status[]&:types[]&:verificationStatus&:search&:sortBy&:direction",

    HoldersDetailArea: "/data/account-holders/:accountHolderId/*",
    HoldersDetailRoot: "/data/account-holders/:accountHolderId",
    HoldersDetailAccounts: "/data/account-holders/:accountHolderId/accounts?:sortBy&:direction",
    // "HoldersDetailPaymentMandates": "/account-holders/:accountHolderId/payment-mandates",
    HoldersDetailUltimateBeneficialOwners:
      "/data/account-holders/:accountHolderId/ultimate-benificial-owners",
    HoldersDetailFundingLimitChangeRequests:
      "/data/account-holders/:accountHolderId/funding-limit-change-requests",
    HoldersDetailFundingLimitChangeRequestsNew:
      "/data/account-holders/:accountHolderId/funding-limit-change-requests/new",

    AccountsArea: "/data/accounts/*",
    AccountsRoot: "/data/accounts?:paymentLevels[]&:status[]&:sortBy&:direction&:search",

    AccountDetailArea: "/data/accounts/:accountId/*",
    AccountDetailRoot: "/data/accounts/:accountId",
    AccountDetailTransactions:
      "/data/accounts/:accountId/transactions?:isAfterUpdatedAt&:isBeforeUpdatedAt&:paymentProduct[]&:search&:status[]&:type[]&:sortBy&:direction",

    AccountDetailTransactionsDetail: "/data/accounts/:accountId/transactions/:transactionId",
    AccountDetailTransactionsDetailSupportingDocuments:
      "/data/accounts/:accountId/transactions/:transactionId/supporting-documents",

    AccountDetailAccountMemberships:
      "/data/accounts/:accountId/account-memberships?:status[]&:canViewAccount&:canInitiatePayments&:canManageAccountMembership&:canManageBeneficiaries&:canManageCards&:search&:sortBy&:direction",
    AccountDetailVirtualIbans: "/data/accounts/:accountId/virtual-ibans",
    AccountDetailFundingSources: "/data/accounts/:accountId/funding-sources",
    AccountDetailFundingSourcesDetail: "/data/accounts/:accountId/funding-sources/:fundingSourceId",
    AccountDetailReceivedDirectDebitMandates:
      "/data/accounts/:accountId/received-direct-debit-mandates",
    AccountDetailReceivedDirectDebitMandatesDetail:
      "/data/accounts/:accountId/received-direct-debit-mandates/:receivedDirectDebitMandateId",

    AccountDetailAccountStatementsArea: "/data/accounts/:accountId/account-statements/*",
    AccountDetailAccountStatementsRoot:
      "/data/accounts/:accountId/account-statements?:isAfterUpdatedAt&:isBeforeUpdatedAt",
    AccountDetailAccountStatementsNew:
      "/data/accounts/:accountId/account-statements/new?:isAfterUpdatedAt&:isBeforeUpdatedAt",

    AccountDetailInvoices: "/data/accounts/:accountId/invoices",

    AccountDetailMerchantListLegacy: "/data/accounts/:accountId/merchant-profiles/list",

    ...createGroup("AccountDetailMerchant", "/data/accounts/:accountId/merchants", {
      List: "/?:status{Active|Inactive}&:new{true}",
      Profile: "/:merchantProfileId?:new{true}",
    }),

    AccountMembershipsArea: "/data/account-memberships/*",
    AccountMembershipsRoot:
      "/data/account-memberships?:status[]&:canViewAccount&:canInitiatePayments&:canManageAccountMembership&:canManageBeneficiaries&:canManageCards&:sortBy&:direction&:search",

    AccountMembershipsDetailArea: "/data/account-memberships/:accountMembershipId/*",
    AccountMembershipsDetailRoot: "/data/account-memberships/:accountMembershipId",
    AccountMembershipsDetailCards:
      "/data/account-memberships/:accountMembershipId/cards?:statuses[]&:types[]&:search",

    ...createGroup("Insights", "/insights", {
      Area: "/*",
      Overview: "/overview",
      AllTransactions: "/transactions-all",
      CreditTransfer: "/transactions-credit-transfer",
      Cards: "/transactions-cards",
      OnboardingAndAccounts: "/onboarding-accounts",
      IdentityVerification: "/identity-verification",
      Consents: "/consents",
    }),

    DevelopersWebhooksArea: "/developers/webhooks/*",
    DevelopersWebhooksRoot: "/developers/webhooks",
    DevelopersWebhooksNew: "/developers/webhooks/new",

    DevelopersWebhooksSubscriptionArea: "/developers/webhooks/:webhookSubscriptionId/*",
    DevelopersWebhooksSubscriptionRoot: "/developers/webhooks/:webhookSubscriptionId",
    DevelopersWebhooksSubscriptionEventLogs:
      "/developers/webhooks/:webhookSubscriptionId/events?:statusText&:resourceId&:eventId&:eventType&:endDate&:startDate",
    DevelopersWebhooksSubscriptionEventLog:
      "/developers/webhooks/:webhookSubscriptionId/events/:logId",

    DevelopersUsersArea: "/developers/sandbox-users/*",
    DevelopersUsersRoot: "/developers/sandbox-users?:new",

    DevelopersSimulatorArea: "/developers/simulator/*",
    DevelopersSimulatorRoot: "/developers/simulator",

    DevelopersPaymentControl: "/developers/payment-control",
    DevelopersConsentNotification: "/developers/consent-notification",

    DevelopersServerConsentArea: "/developers/server-consent/*",
    DevelopersServerConsentDraft: "/developers/server-consent/draft",
    DevelopersServerConsentPending: "/developers/server-consent/pending",
    DevelopersServerConsentCurrent: "/developers/server-consent/current",

    SettingsArea: "/settings/*",
    SettingsRoot: "/settings",
    SettingsBrandingArea: "/settings/branding/*",
    SettingsBrandingRoot: "/settings/branding?:firstVisit",
    SettingsBrandingDraft: "/settings/branding/draft",
    SettingsBrandingPending: "/settings/branding/pending",
    SettingsBrandingCurrent: "/settings/branding/current",
    SettingsOnboarding: "/settings/onboarding",
    SettingsWebBanking: "/settings/web-banking",
    SettingsCardProductsArea: "/settings/card-products/*",
    SettingsCardProducts: "/settings/card-products",
    SettingsCardProductsNew: "/settings/card-products/new",
    SettingsCardProductsDetailArea: "/settings/card-products/:cardProductId/*",
    SettingsCardProductsDetailOptions: "/settings/card-products/:cardProductId/options",

    ...createGroup("Transactions", "/data/transactions", {
      Area: "/*",
      List: "?:isAfterUpdatedAt&:isBeforeUpdatedAt&:paymentProduct[]&:search&:status[]&:type[]&:sortBy&:direction",
      ...createGroup("Detail", "/:transactionId", {
        Area: "/*",
        Root: "/",
        SupportingDocuments: "/supporting-documents",
      }),
    }),
  }),

  ...createGroup("Live", "/projects/:projectId/live?:activate", {
    SettingsCardProductsDetailDraft: "/settings/card-products/:cardProductId",
    SettingsCardProductsDetailPending: "/settings/card-products/:cardProductId/pending",
    SettingsCardProductsDetailCurrent: "/settings/card-products/:cardProductId/current",
  }),

  ...createGroup("Sandbox", "/projects/:projectId/sandbox?:activate", {
    Activation: "/activation",
    DevelopersUsersEdit: "/developers/sandbox-users/edit/:userId",
    SettingsCardProductsDetailCurrent: "/settings/card-products/:cardProductId",

    ...createGroup("DevelopersSimulator", "/developers/simulator", {
      SepaCreditTransferArea: "/sepa-credit-transfer/*",
      SepaCreditTransferReceptionIn: "/sepa-credit-transfer/in-reception",
      SepaCreditTransferReturnIn: "/sepa-credit-transfer/in-return",
      SepaCreditTransferRecallIn: "/sepa-credit-transfer/in-recall",
      SepaCreditTransferBookingOut: "/sepa-credit-transfer/out-booking",
      SepaCreditTransferRejectionOut: "/sepa-credit-transfer/out-rejection",
      SepaCreditTransferRecallOut: "/sepa-credit-transfer/out-recall",
      SepaCreditTransferReturnOut: "/sepa-credit-transfer/out-return",

      SepaDirectDebitArea: "/sepa-direct-debit/*",
      SepaDirectDebitReceptionOut: "/sepa-direct-debit/out-reception",
      SepaDirectDebitCancelOut: "/sepa-direct-debit/out-cancel",
      SepaDirectDebitRejectOut: "/sepa-direct-debit/out-reject",
      SepaDirectDebitReturnOut: "/sepa-direct-debit/out-return",
      SepaDirectDebitReverseOut: "/sepa-direct-debit/out-reverse",

      InternationalCreditTransferArea: "/international-credit-transfer/*",
      InternationalCreditTransferBooking: "/international-credit-transfer/booking",
      InternationalCreditTransferRejection: "/international-credit-transfer/rejection",
      InternationalCreditTransferReception: "/international-credit-transfer/reception",

      CardArea: "/card/*",
      CardAuthorizationOut: "/card/out-authorization",
      CardAdviceOut: "/card/out-advice",
      CardAuthorizationReleaseOut: "/card/out-authorization-release",
      CardDebitOut: "/card/out-debit",
      CardDebitReversal: "/card/debit-reversal",
      CardCreditOut: "/card/out-credit",
      CardCreditReversal: "/card/credit-reversal",
      CardSimulationPhysicalCardDelivery: "/card/simulation-physical-card-delivery",
      CardSimulationPhysicalCardReception: "/card/simulation-physical-card-reception",
      CardSimulationPhysicalCardRenewal: "/card/simulation-physical-card-renewal",
      CardTransactionEnrichment: "/card/simulation-card-transaction-enrichment",

      XPayArea: "/x-pay/*",
      XPaySimulateDigitalization: "/x-pay/simulate-digitalization",
      XPayFinishInApp: "/x-pay/finish-in-app",

      AccountHolderArea: "/company-account-holder/*",
      AccountHolderUpdateSupportingDocumentCollectionStatus:
        "/company-account-holder/update-supporting-document-collection-status",
      AccountHolderUpdateVerificationStatus: "/company-account-holder/update-verification-status",
      AccountHolderUpdateFundingLimitSettingsChangeRequest:
        "/company-account-holder/update-funding-limit-settings-change-request",
      AccountHolderUpdateSupportingDocumentStatus:
        "/company-account-holder/update-supporting-document-status",
      AccountHolderRequestSupportingDocuments:
        "/company-account-holder/request-supporting-documents",

      MerchantAcquiringArea: "/merchant-acquiring/*",
      MerchantAcquiringUpdateProfileStatus: "/merchant-acquiring/update-profile-status",
      MerchantAcquiringUpdatePaymentProductStatus:
        "/merchant-acquiring/update-payment-product-status",
      PromoteAccountHolderToProjectOwner:
        "/company-account-holder/promote-account-holder-to-project-owner",

      SandboxUsersArea: "/sandbox-users/*",
      SandboxUsersCreateIdentification: "/sandbox-users/create-identification?:userId",
      SandboxUsersUpdateIdentification: "/sandbox-users/update-identification",

      BillingArea: "/billing/*",
      BillingGenerateInvoice: "/billing/generate-invoice",
      BillingChargeManualBankingFee: "/billing/charge-manual-banking-fee",

      CapitalDepositArea: "/capital-deposit/*",
      CapitalDepositValidate: "/capital-deposit/validate",
      CapitalDepositUpdateDocument: "/capital-deposit/update-document",
    }),
  }),
} as const;

export const Router = createRouter(routes);

export type RouteParams = InferRoutes<typeof Router>;
export type RouteName = keyof RouteParams;

export const adminRoutes = ["ProjectAdminTeam"] as const satisfies RouteName[];

export const topLevelRoutes = [
  "ProjectAdminTeam",

  "DevelopersApi",
  "OnboardingRoot",
  "SettingsBrandingRoot",
  "SettingsCardProducts",
  "SettingsOnboarding",
  "SettingsWebBanking",
  "HoldersRoot",
  "AccountsRoot",
  "AccountMembershipsRoot",
  "CardRoot",
  "DevelopersWebhooksRoot",
  "DevelopersConsentNotification",
  "DevelopersPaymentControl",
  "DevelopersServerConsentDraft",
  "DevelopersUsersRoot",
  "DevelopersSimulatorRoot",
] as const satisfies RouteName[];

export const simulatorTopLevelRoutes = [
  "DevelopersSimulatorRoot",

  "SandboxDevelopersSimulatorSepaCreditTransferArea",
  "SandboxDevelopersSimulatorSepaDirectDebitArea",
  "SandboxDevelopersSimulatorCardArea",
  "SandboxDevelopersSimulatorXPayArea",
  "SandboxDevelopersSimulatorAccountHolderArea",
  "SandboxDevelopersSimulatorMerchantAcquiringArea",
  "SandboxDevelopersSimulatorInternationalCreditTransferArea",
  "SandboxDevelopersSimulatorSandboxUsersArea",
  "SandboxDevelopersSimulatorBillingArea",
  "SandboxDevelopersSimulatorCapitalDepositArea",
] as const satisfies RouteName[];

export const simulatorSubRoutes = [
  "SandboxDevelopersSimulatorSepaCreditTransferReceptionIn",
  "SandboxDevelopersSimulatorSepaCreditTransferReturnIn",
  "SandboxDevelopersSimulatorSepaCreditTransferRecallIn",
  "SandboxDevelopersSimulatorSepaCreditTransferBookingOut",
  "SandboxDevelopersSimulatorSepaCreditTransferRejectionOut",
  "SandboxDevelopersSimulatorSepaCreditTransferRecallOut",
  "SandboxDevelopersSimulatorSepaCreditTransferReturnOut",

  "SandboxDevelopersSimulatorSepaDirectDebitReceptionOut",
  "SandboxDevelopersSimulatorSepaDirectDebitCancelOut",
  "SandboxDevelopersSimulatorSepaDirectDebitRejectOut",
  "SandboxDevelopersSimulatorSepaDirectDebitReturnOut",
  "SandboxDevelopersSimulatorSepaDirectDebitReverseOut",

  "SandboxDevelopersSimulatorCardAuthorizationOut",
  "SandboxDevelopersSimulatorCardAdviceOut",
  "SandboxDevelopersSimulatorCardAuthorizationReleaseOut",
  "SandboxDevelopersSimulatorCardDebitOut",
  "SandboxDevelopersSimulatorCardDebitReversal",
  "SandboxDevelopersSimulatorCardCreditOut",
  "SandboxDevelopersSimulatorCardCreditReversal",
  "SandboxDevelopersSimulatorCardSimulationPhysicalCardDelivery",
  "SandboxDevelopersSimulatorCardSimulationPhysicalCardReception",
  "SandboxDevelopersSimulatorCardSimulationPhysicalCardRenewal",
  "SandboxDevelopersSimulatorCardTransactionEnrichment",

  "SandboxDevelopersSimulatorXPaySimulateDigitalization",
  "SandboxDevelopersSimulatorXPayFinishInApp",

  "SandboxDevelopersSimulatorAccountHolderUpdateVerificationStatus",
  "SandboxDevelopersSimulatorAccountHolderUpdateFundingLimitSettingsChangeRequest",
  "SandboxDevelopersSimulatorPromoteAccountHolderToProjectOwner",

  "SandboxDevelopersSimulatorMerchantAcquiringUpdateProfileStatus",
  "SandboxDevelopersSimulatorMerchantAcquiringUpdatePaymentProductStatus",
  "SandboxDevelopersSimulatorAccountHolderUpdateSupportingDocumentCollectionStatus",
  "SandboxDevelopersSimulatorAccountHolderUpdateSupportingDocumentStatus",
  "SandboxDevelopersSimulatorAccountHolderRequestSupportingDocuments",

  "SandboxDevelopersSimulatorInternationalCreditTransferBooking",
  "SandboxDevelopersSimulatorInternationalCreditTransferRejection",
  "SandboxDevelopersSimulatorInternationalCreditTransferReception",

  "SandboxDevelopersSimulatorSandboxUsersCreateIdentification",
  "SandboxDevelopersSimulatorSandboxUsersUpdateIdentification",

  "SandboxDevelopersSimulatorBillingGenerateInvoice",
  "SandboxDevelopersSimulatorBillingChargeManualBankingFee",

  "SandboxDevelopersSimulatorCapitalDepositValidate",
  "SandboxDevelopersSimulatorCapitalDepositUpdateDocument",
] as const satisfies RouteName[];

export const userRoutes = ["UserList", "UserDetail"] as const satisfies RouteName[];

export const onboardingRoutes = [
  "OnboardingRoot",
  "OnboardingDetailArea",
] as const satisfies RouteName[];

export const onboardingDetailRoutes = [
  "OnboardingDetailRoot",
  "OnboardingDetailUltimateBeneficialOwners",
  "OnboardingDetailSupportingDocuments",
  "OnboardingDetailSupportingDocumentsNew",
] as const satisfies RouteName[];

export const capitalDepositRoutes = [
  "CapitalDepositList",
  "CapitalDepositDetailArea",
] as const satisfies RouteName[];

export const capitalDepositDetailRoutes = [
  "CapitalDepositDetailRoot",
  "CapitalDepositDetailShareholdersList",
  "CapitalDepositDetailShareholderArea",
] as const satisfies RouteName[];

export const capitalDepositShareholderRoutes = [
  "CapitalDepositDetailShareholderRoot",
] as const satisfies RouteName[];

export const cardRoutes = ["CardRoot", "CardDetailArea"] as const satisfies RouteName[];

export const sandboxUsersRoutes = [
  "DevelopersUsersRoot",
  "SandboxDevelopersUsersEdit",
] as const satisfies RouteName[];

export const cardDetailRoutes = [
  "CardDetailArea",
  "CardDetailRoot",
  "CardDetailDigitalCards",
  "CardDetailTransactions",
] as const satisfies RouteName[];

export const webhooksRoutes = [
  "DevelopersWebhooksRoot",
  "DevelopersWebhooksNew",
  "DevelopersWebhooksSubscriptionArea",
] as const satisfies RouteName[];

export const webhookDetailRoutes = [
  "DevelopersWebhooksSubscriptionRoot",
  "DevelopersWebhooksSubscriptionEventLogs",
  "DevelopersWebhooksSubscriptionEventLog",
] as const satisfies RouteName[];

export const accountHoldersRoutes = [
  "HoldersRoot",
  "HoldersDetailArea",
  "HoldersVerificationArea",
] as const satisfies RouteName[];

export const accountHolderDetailRoutes = [
  "HoldersDetailRoot",
  "HoldersDetailAccounts",
  // "HoldersDetailPaymentMandates",
  "HoldersDetailUltimateBeneficialOwners",
  "HoldersDetailFundingLimitChangeRequests",
  "HoldersDetailFundingLimitChangeRequestsNew",
] as const satisfies RouteName[];

export const accountMembershipsRoutes = [
  "AccountMembershipsRoot",
  "AccountMembershipsDetailArea",
] as const satisfies RouteName[];

export const accountMembershipsDetailRoutes = [
  "AccountMembershipsDetailRoot",
  "AccountMembershipsDetailCards",
] as const satisfies RouteName[];

export const accountRoutes = ["AccountsRoot", "AccountDetailArea"] as const satisfies RouteName[];

export const accountDetailRoutes = [
  "AccountDetailRoot",
  "AccountDetailTransactions",
  "AccountDetailTransactionsDetail",
  "AccountDetailTransactionsDetailSupportingDocuments",
  "AccountDetailAccountMemberships",
  "AccountDetailVirtualIbans",
  "AccountDetailFundingSources",
  "AccountDetailFundingSourcesDetail",
  "AccountDetailReceivedDirectDebitMandates",
  "AccountDetailReceivedDirectDebitMandatesDetail",
  "AccountDetailAccountStatementsRoot",
  "AccountDetailAccountStatementsNew",
  "AccountDetailInvoices",
  "AccountDetailMerchantListLegacy",
  "AccountDetailMerchantList",
  "AccountDetailMerchantProfile",
] as const satisfies RouteName[];

export const projectSectionsRoutes = [
  "ProjectWithEnvRoot",
  "DevelopersArea",
  "DataArea",
  "InsightsArea",
  "SettingsArea",
  "SandboxActivation",
] as const satisfies RouteName[];

export const dataRoutes = [
  "ProjectWithEnvRoot",
  "UserArea",
  "OnboardingArea",
  "CapitalDepositArea",
  "HoldersArea",
  "HoldersVerificationArea",
  "AccountsArea",
  "AccountMembershipsArea",
  "CardArea",
  "TransactionsArea",
] as const satisfies RouteName[];

export const insightsRoutes = [
  "InsightsOverview",
  "InsightsAllTransactions",
  "InsightsCreditTransfer",
  "InsightsCards",
  "InsightsOnboardingAndAccounts",
  "InsightsIdentityVerification",
  "InsightsConsents",
] as const satisfies RouteName[];

export const settingsRoots = [
  "SettingsRoot",
  "SettingsBrandingArea",
  "SettingsBrandingRoot",
  "SettingsBrandingDraft",
  "SettingsBrandingPending",
  "SettingsBrandingCurrent",
  "SettingsOnboarding",
  "SettingsWebBanking",
  "SettingsCardProductsArea",
] as const satisfies RouteName[];

export const brandingRoots = [
  "SettingsBrandingRoot",
  "SettingsBrandingDraft",
  "SettingsBrandingPending",
  "SettingsBrandingCurrent",
] as const satisfies RouteName[];

export const developerRoots = [
  "DevelopersApi",
  "DevelopersWebhooksArea",
  "DevelopersPaymentControl",
  "DevelopersConsentNotification",
  "DevelopersServerConsentArea",
  "DevelopersUsersArea",
  "DevelopersSimulatorArea",
] as const satisfies RouteName[];

export const settingsCardProductsRoots = [
  "SettingsCardProducts",
  "SettingsCardProductsNew",
  "SettingsCardProductsDetailArea",
] as const satisfies RouteName[];

export const settingsCardProductsDetailRoots = [
  "SettingsCardProductsDetailOptions",

  "LiveSettingsCardProductsDetailDraft",
  "LiveSettingsCardProductsDetailPending",
  "LiveSettingsCardProductsDetailCurrent",

  "SandboxSettingsCardProductsDetailCurrent",
] as const satisfies RouteName[];

type Routes = typeof routes;

type Paths = {
  [K in keyof Routes]: Routes[K] extends `${infer Path extends string}?${string}`
    ? Path
    : Routes[K];
};

export const finitePaths = Object.fromEntries(
  Dict.entries(routes)
    .map(([key, value]) => [key, value.split("?")[0] ?? ""] as const)
    .filter(([, value]) => !value.endsWith("/*")),
) as {
  [K in keyof Paths as Paths[K] extends `${string}/*` ? never : K]: Paths[K];
};

export const finiteRouteNames = Dict.keys(finitePaths);

export type GetRouteParams<K extends RouteName> = Extract<
  ReturnType<typeof Router.getRoute>,
  { name: K }
>["params"];

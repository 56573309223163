import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Box } from "@swan-io/lake/src/components/Box";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import {
  BalanceCell,
  CopyableRegularTextCell,
  EndAlignedCell,
  LinkCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  SimpleTitleCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/Cells";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Flag } from "@swan-io/lake/src/components/Flag";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { ColumnConfig, PlainListView } from "@swan-io/lake/src/components/PlainListView";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import {
  ColumnConfig as FixedColumnConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish, isNotNullishOrEmpty, isNullish } from "@swan-io/lake/src/utils/nullish";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { getCountryByCCA3, isCountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { Except } from "type-fest";
import {
  AccountStatusTag,
  ConsentStatusTag,
  VerificationStatusTag,
} from "../components/BusinessTags";
import { EmptyTile } from "../components/EmptyTile";
import { ErrorView } from "../components/ErrorView";
import { MembershipPermissionsTag } from "../components/MembershipPermissionsTag";
import { TrackPressable } from "../components/TrackPressable";
import { UserIdentificationProcessHelp } from "../components/UserIdentificationProcessHelp";
import {
  NB_MAX_IDENTIFICATIONS_TO_DISPLAY,
  UserIdentificationsTile,
} from "../components/UserIdentificationsTile";
import {
  AccountMembershipPermissionsFragment,
  DeactivateUserDocument,
  GetUserDocument,
  GetUserQuery,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { getDiffFromNow } from "../utils/date";
import { formatCurrency, t } from "../utils/i18n";
import { printIbanWithEllipsis } from "../utils/iban";
import { Router } from "../utils/routes";
import { getConsentPurpose, getDiffAgoLabel } from "../utils/templateTranslations";
import { NotFoundPage } from "./NotFoundPage";

const styles = StyleSheet.create({
  tileContainer: {
    flex: 1,
  },
  userInfoLabel: {
    minHeight: 65,
  },
  tileWithList: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    paddingTop: spacings[32],
  },
  listTitleContainer: {
    paddingHorizontal: spacings[32],
  },
  listRow: {
    boxShadow: "none",
    paddingHorizontal: spacings[16],
  },
  unknownValue: {
    fontStyle: "italic",
  },
  align: {
    display: "flex",
    alignItems: "center",
  },
  emailsButton: {
    minWidth: "initial",
    paddingRight: spacings[12],
    paddingLeft: spacings[12],
  },
  emailsPopover: {
    paddingVertical: spacings[20],
    paddingHorizontal: spacings[24],
  },
  rowLink: {
    display: "flex",
    flexDirection: "column",
  },
  cardDesign: {
    width: 50,
    height: 32,
    borderRadius: radii[4],
    overflow: "hidden",
  },
  identificationLevels: {
    flexWrap: "wrap",
  },
  identificationLevel: {
    marginBottom: spacings[8],
  },
});

const NB_MAX_ACCOUNT_TO_DISPLAY = 3;
const NB_MAX_CARD_TO_DISPLAY = 4;
const NB_MAX_CONSENTS_TO_DISPLAY = 5;

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
};

type Props = {
  userId: string;
};

export const UserDetailsPage = ({ userId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data] = useQuery(GetUserDocument, {
    maxAccountCount: NB_MAX_ACCOUNT_TO_DISPLAY,
    cardsByAccountCount: NB_MAX_CARD_TO_DISPLAY,
    maxConsentsCount: NB_MAX_CONSENTS_TO_DISPLAY,
    maxIdentifications: NB_MAX_IDENTIFICATIONS_TO_DISPLAY,
    userId,
  });

  const [deactivateUser, userDeactivationData] = useMutation(DeactivateUserDocument);

  const [
    deactivateUserVisible,
    { open: openDeactivateUserModal, close: closeDeactivateUserModal },
  ] = useDisclosure(false);

  const handleDeactivateUser = () => {
    deactivateUser({
      input: {
        userId,
      },
    })
      .mapOk(data => data.deactivateUser)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        showToast({ variant: "success", title: t("toast.success.deactivateUserSuccessful") });
        closeDeactivateUserModal();
      })
      .tapError(error => {
        match(error)
          .with({ __typename: "UserCannotBeDeactivatedRejection" }, () => {
            showToast({ variant: "error", error, title: t("toast.error.userCannotBeDeactivated") });
          })
          .otherwise(() => {
            showToast({ variant: "error", error, title: translateError(error) });
          });
      });
  };

  useCrumb(
    useMemo(
      () =>
        match(data)
          .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ user }) => ({
            label: [user?.firstName, user?.lastName].filter(isNotNullishOrEmpty).join(" "),
            link: Router.UserDetail({ projectId, projectEnv, userId }),
          }))
          .otherwise(() => undefined),
      [data, projectEnv, projectId, userId],
    ),
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TilePlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok({ user: P.nullish })), () => <NotFoundPage />)
    .with(
      AsyncData.P.Done(Result.P.Ok(P.select({ user: P.nonNullable }))),
      ({ user, consents }) => {
        const fullName = [user.firstName, user.lastName].filter(isNotNullishOrEmpty).join(" ");

        return (
          <>
            {/** Bit of a magic number here, but that roughly goes to the minimum
             * width required by the two tiles when next to each other horizontally */}
            <ResponsiveContainer breakpoint={1000}>
              {({ large }) => (
                <Box direction={large ? "row" : "column"} alignItems="stretch">
                  <UserInfoTile user={user} />
                  <Space width={24} height={24} />
                  <UserIdentificationsTile user={user} />
                </Box>
              )}
            </ResponsiveContainer>

            <Space height={24} />

            <UserAccountsTile
              accounts={user.accountMemberships.edges
                .map(edge => {
                  if (isNullish(edge.node.account)) {
                    return null;
                  }
                  return {
                    ...edge.node.account,
                    permissions: {
                      canViewAccount: edge.node.canViewAccount,
                      canInitiatePayments: edge.node.canInitiatePayments,
                      canManageBeneficiaries: edge.node.canManageBeneficiaries,
                      canManageAccountMembership: edge.node.canManageAccountMembership,
                      activeCards: edge.node.activeCards,
                    },
                  };
                })
                .filter(isNotNullish)}
              nbTotalAccounts={user.accountMemberships.totalCount}
              // FIXME: fullName filter doesn't work atm. need to be uncommented when fixed
              // fullName={fullName}
            />

            <Space height={24} />

            <TileGrid>
              <UserCardsTile
                cards={user.accountMemberships.edges
                  .map(edge => edge.node.cards.edges.map(edge => edge.node))
                  .flat()
                  // we can limit the number of cards by account only, so we need to slice the array in client side
                  .slice(0, NB_MAX_CARD_TO_DISPLAY)}
                // we compute the total number of cards only if all accounts are loaded
                // because we need all accounts to get the total number of cards
                nbTotalCards={
                  user.accountMemberships.totalCount <= user.accountMemberships.edges.length
                    ? Option.Some(
                        user.accountMemberships.edges
                          .map(edge => edge.node.cards.totalCount)
                          .reduce((acc, curr) => acc + curr, 0),
                      )
                    : Option.None()
                }
                fullName={fullName}
              />

              <UserConsentsTile consents={consents.edges.map(edge => edge.node)} />
            </TileGrid>

            {user.status === "Active" && (
              <Box alignItems="start">
                <TrackPressable action="Deactivate user">
                  <LakeButton
                    color="negative"
                    icon="subtract-circle-regular"
                    mode="secondary"
                    size="small"
                    onPress={openDeactivateUserModal}
                  >
                    {t("user.details.deactivateUser")}
                  </LakeButton>
                </TrackPressable>
              </Box>
            )}

            <LakeModal
              icon="subtract-circle-regular"
              visible={deactivateUserVisible}
              title={t("user.details.deactivateUser")}
              onPressClose={closeDeactivateUserModal}
            >
              <LakeText>{t("user.details.deactivateUser.description")}</LakeText>
              <Space height={32} />

              <LakeButtonGroup>
                <LakeButton
                  mode="secondary"
                  color="gray"
                  onPress={closeDeactivateUserModal}
                  grow={true}
                >
                  {t("common.cancel")}
                </LakeButton>

                <LakeButton
                  color="current"
                  onPress={() => handleDeactivateUser()}
                  loading={userDeactivationData.isLoading()}
                  grow={true}
                >
                  {t("user.details.deactivate")}
                </LakeButton>
              </LakeButtonGroup>
            </LakeModal>

            <Space height={24} />
          </>
        );
      },
    )
    .exhaustive();
};

type UserEmailsProps = {
  emails: string[];
};

const UserEmails = ({ emails }: UserEmailsProps) => {
  const ref = useRef<View>(null);
  const [opened, setOpened] = useState(false);

  return (
    <>
      <LakeButton
        ref={ref}
        size="small"
        mode="tertiary"
        icon="chevron-down-filled"
        ariaLabel={t("common.showMore")}
        iconPosition="end"
        style={styles.emailsButton}
        onPress={() => setOpened(true)}
      >
        +{emails.length}
      </LakeButton>

      <Popover referenceRef={ref} visible={opened} onDismiss={() => setOpened(false)}>
        <View style={styles.emailsPopover} tabIndex={0}>
          {emails.map((email, index) => (
            <Fragment key={index}>
              <Box direction="row" alignItems="center">
                <LakeText color={colors.gray[900]}>{email}</LakeText>
                <Fill minWidth={24} />

                <LakeCopyButton
                  valueToCopy={email}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              </Box>

              {index < emails.length - 1 && <Space height={16} />}
            </Fragment>
          ))}
        </View>
      </Popover>
    </>
  );
};

type UserInfoTileProps = {
  user: NonNullable<GetUserQuery["user"]>;
};

const UserInfoTile = ({ user }: UserInfoTileProps) => {
  const initials = [user.firstName, user.lastName].map(name => name?.[0]).join("");
  const birthDate = isNotNullishOrEmpty(user.birthDate) ? dayjs(user.birthDate).format("LL") : null;
  const [isHelpModalOpen, setIsHelpModalOpen] = useDisclosure(false);
  const {
    id,
    firstName,
    lastName,
    mobilePhoneNumber,
    nationalityCCA3,
    preferredNotificationChannel,
    joinedAt,
    accountMemberships,
    identificationLevels,
    status,
  } = user;

  const [firstEmail, ...emails] = accountMemberships.edges
    .map(edge => edge.node.email)
    .filter((email, index, array) => array.indexOf(email) === index);
  const isExpert = identificationLevels?.expert ?? false;
  const isQES = identificationLevels?.QES ?? false;
  const isPVID = identificationLevels?.PVID ?? false;

  return (
    <Tile flexGrow={1}>
      <Box direction="row" alignItems="center" justifyContent="spaceBetween">
        <Box direction="row" alignItems="center">
          <Avatar initials={initials} size={68} />
          <Space width={32} />

          <View>
            <LakeHeading level={2} variant="h3">
              {firstName} {lastName}
            </LakeHeading>

            <Space height={8} />

            <Box direction="row" alignItems="center">
              {[
                isNotNullishOrEmpty(mobilePhoneNumber) && (
                  <LakeText key="user-details-phone-number" variant="smallRegular">
                    {mobilePhoneNumber}
                  </LakeText>
                ),
                isNotNullishOrEmpty(birthDate) && (
                  <LakeText key="user-details-birthdate" variant="smallRegular">
                    {birthDate}
                  </LakeText>
                ),
              ]
                .filter(isNotNullish)
                .flatMap((value, index, array) =>
                  array.length - 1 !== index
                    ? [
                        value,
                        <Separator key={`user-details-${index}`} horizontal={true} space={12} />,
                      ]
                    : value,
                )}
            </Box>
          </View>
        </Box>
      </Box>

      <Space height={32} />

      <Box direction="column" alignItems="stretch">
        <View style={styles.tileContainer}>
          <ReadOnlyFieldList>
            <LakeLabel
              style={styles.userInfoLabel}
              label={t("user.details.user.email")}
              type="view"
              render={() =>
                isNotNullish(firstEmail) ? (
                  <LakeText color={colors.gray[900]}>{firstEmail}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
              actions={
                isNotNullish(firstEmail) ? (
                  <>
                    <LakeCopyButton
                      valueToCopy={firstEmail}
                      copyText={t("copyButton.copyTooltip")}
                      copiedText={t("copyButton.copiedTooltip")}
                    />

                    {emails.length > 0 && (
                      <>
                        <Space width={12} />
                        <UserEmails emails={emails} />
                      </>
                    )}
                  </>
                ) : undefined
              }
            />

            {isNotNullish(status) && (
              <LakeLabel
                style={styles.userInfoLabel}
                label={t("user.details.user.status")}
                type="view"
                render={() => (
                  <>
                    {match(status)
                      .with("Active", () => (
                        <Tag color="positive">{t("user.details.user.status.active")}</Tag>
                      ))
                      .with("Blocked", () => (
                        <Tag color="negative">{t("user.details.user.status.blocked")}</Tag>
                      ))
                      .with("Deactivated", () => (
                        <Tag color="gray">{t("user.details.user.status.deactivated")}</Tag>
                      ))
                      .exhaustive()}
                  </>
                )}
              />
            )}

            <LakeLabel
              style={styles.userInfoLabel}
              label={t("user.details.user.joinedProject")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>{dayjs(joinedAt).format("LL")}</LakeText>
              )}
            />

            <LakeLabel
              style={styles.userInfoLabel}
              label={t("user.details.user.userId")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]} numberOfLines={1}>
                  {id}
                </LakeText>
              )}
              actions={
                <LakeCopyButton
                  valueToCopy={id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              style={styles.userInfoLabel}
              label={t("user.details.user.nationality")}
              type="view"
              render={() => {
                const country = isCountryCCA3(nationalityCCA3)
                  ? getCountryByCCA3(nationalityCCA3)
                  : undefined;

                return (
                  <LakeText style={styles.align} numberOfLines={1} color={colors.gray[900]}>
                    {isNotNullish(country) ? (
                      <>
                        <Flag code={country.cca2} width={14} />
                        <Space width={8} />

                        {country.name}
                      </>
                    ) : (
                      UNKNOWN_VALUE
                    )}
                  </LakeText>
                );
              }}
            />

            <LakeLabel
              style={styles.userInfoLabel}
              label={t("user.details.user.notificationMethod")}
              type="view"
              render={() => (
                <Tag>
                  {preferredNotificationChannel === "App" ? t("common.app") : t("common.sms")}
                </Tag>
              )}
            />

            <LakeLabel
              style={styles.userInfoLabel}
              label={t("user.details.user.identificationLevel")}
              type="view"
              actions={
                <LakeButton
                  mode="tertiary"
                  onPress={setIsHelpModalOpen.open}
                  icon="question-circle-regular"
                  ariaLabel={t("user.details.identifications.help")}
                />
              }
              render={() => (
                <Box direction="row" alignItems="center" style={styles.identificationLevels}>
                  <Tag
                    label={t("user.details.user.identificationLevel.expert")}
                    color={isExpert ? "positive" : "gray"}
                    style={styles.identificationLevel}
                  >
                    {isExpert ? t("common.true") : t("common.false")}
                  </Tag>

                  <Space width={12} />

                  <Tag
                    label={t("user.details.user.identificationLevel.qes")}
                    color={isQES ? "positive" : "gray"}
                    style={styles.identificationLevel}
                  >
                    {isQES ? t("common.true") : t("common.false")}
                  </Tag>

                  <Space width={12} />

                  <Tag
                    label={t("user.details.user.identificationLevel.pvid")}
                    color={isPVID ? "positive" : "gray"}
                    style={styles.identificationLevel}
                  >
                    {isPVID ? t("common.true") : t("common.false")}
                  </Tag>
                </Box>
              )}
            />
          </ReadOnlyFieldList>
        </View>
      </Box>

      <LakeModal
        visible={isHelpModalOpen}
        onPressClose={setIsHelpModalOpen.close}
        title={t("user.details.identifications.identificationProcess")}
        icon="question-circle-regular"
      >
        <UserIdentificationProcessHelp />
      </LakeModal>
    </Tile>
  );
};

type UserAccount = NonNullable<
  NonNullable<GetUserQuery["user"]>["accountMemberships"]["edges"][number]["node"]["account"]
> & {
  permissions: Except<AccountMembershipPermissionsFragment, "__typename">;
};

const userAccountColumns: FixedColumnConfig<UserAccount, ExtraInfo>[] = [
  {
    id: "accountHolder",
    width: 190,
    title: t("user.details.accounts.table.accountHolder"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { holder }, extraInfo: { projectEnv, projectId } }) => (
      <LinkCell
        variant="smallRegular"
        onPress={() => {
          Router.push("HoldersDetailRoot", { projectId, projectEnv, accountHolderId: holder.id });
        }}
        tooltip={{ placement: "right", content: holder.info.name }}
      >
        {holder.info.name}
      </LinkCell>
    ),
  },
  {
    id: "iban",
    width: 170,
    title: t("user.details.accounts.table.iban"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { IBAN } }) =>
      isNotNullish(IBAN) ? (
        <CopyableRegularTextCell
          variant="smallRegular"
          text={printIbanWithEllipsis(IBAN)}
          textToCopy={IBAN}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
          tooltip={{ placement: "right", content: IBAN }}
        />
      ) : (
        <SimpleRegularTextCell
          variant="smallRegular"
          color={colors.gray[500]}
          text={t("user.details.accounts.table.iban.noIban")}
        />
      ),
  },
  {
    id: "availableBalance",
    width: 170,
    title: t("user.details.accounts.table.availableBalance"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { balances } }) =>
      isNotNullish(balances) ? (
        <BalanceCell
          value={Number(balances.available.value)}
          currency={balances.available.currency}
          formatCurrency={formatCurrency}
          textAlign="left"
          variant="smallRegular"
        />
      ) : null,
  },
  {
    id: "accountLanguage",
    width: 150,
    title: t("user.details.accounts.table.accountLanguage"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { language } }) => (
      <SimpleRegularTextCell
        variant="smallRegular"
        text={match(language)
          .with("de", () => t("user.details.language.german"))
          .with("en", () => t("user.details.language.english"))
          .with("es", () => t("user.details.language.spanish"))
          .with("fr", () => t("user.details.language.french"))
          .with("it", () => t("user.details.language.italian"))
          .with("nl", () => t("user.details.language.dutch"))
          .otherwise(() => t("common.unknown"))}
      />
    ),
  },
  {
    id: "permissions",
    width: 200,
    title: t("user.details.accounts.table.permissions"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item }) => (
      <EndAlignedCell>
        <MembershipPermissionsTag accountMembership={item.permissions} />
      </EndAlignedCell>
    ),
  },
  {
    id: "verificationStatus",
    width: 190,
    title: t("user.details.accounts.table.verificationStatus"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item: { holder } }) => (
      <EndAlignedCell>
        <VerificationStatusTag verificationStatus={holder.verificationStatus} />
      </EndAlignedCell>
    ),
  },
  {
    id: "accountStatus",
    width: 190,
    title: t("user.details.accounts.table.accountStatus"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({
      item: {
        statusInfo: { status },
      },
    }) => (
      <EndAlignedCell>
        <AccountStatusTag accountStatus={status} />
      </EndAlignedCell>
    ),
  },
  {
    id: "actions",
    width: 75,
    title: t("common.table.actions"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: () => (
      <EndAlignedCell>
        <Icon name="arrow-right-filled" color={colors.gray[900]} size={16} />
      </EndAlignedCell>
    ),
  },
];

type UserAccountsTileProps = {
  accounts: UserAccount[];
  nbTotalAccounts: number;
  // FIXME: fullName filter doesn't work atm. need to be uncommented when fixed
  // fullName: string;
};

const UserAccountsTile = ({ accounts, nbTotalAccounts }: UserAccountsTileProps) => {
  const { projectId, projectEnv } = useProjectInfo();
  const extraInfo = useMemo(() => ({ projectEnv, projectId }), [projectEnv, projectId]);

  const getRowLink = useCallback(
    ({
      item: { id },
      extraInfo: { projectId, projectEnv },
    }: LinkConfig<UserAccount, ExtraInfo>) => (
      <Link
        style={styles.rowLink}
        to={Router.AccountDetailRoot({ accountId: id, projectId, projectEnv })}
      />
    ),
    [],
  );

  if (accounts.length === 0) {
    return (
      <EmptyTile
        title={t("user.details.accounts.title")}
        link={t("user.details.accounts.seeAll")}
        icon="person-regular"
        subTitle={t("user.details.accounts.empty.title")}
        description={t("user.details.accounts.empty.description")}
      />
    );
  }

  return (
    <Tile style={styles.tileWithList}>
      <Box direction="row" alignItems="center" style={styles.listTitleContainer}>
        <LakeHeading level={2} variant="h3">
          {t("user.details.accounts.title")}
        </LakeHeading>

        <Fill minWidth={24} />

        {/* FIXME: fullName filter doesn't work atm. need to be uncommented when fixed */}
        <Link to={Router.AccountsRoot({ projectId, projectEnv })}>
          <LakeText>
            {t("user.details.accounts.seeAllWithCount", { count: nbTotalAccounts })}
          </LakeText>
        </Link>
      </Box>

      <Space height={32} />

      <View style={{ height: 48 + 56 * 4 + 4 }}>
        <VirtualizedList
          variant="accented"
          data={accounts}
          keyExtractor={item => item.id}
          extraInfo={extraInfo}
          columns={userAccountColumns.slice(0, userAccountColumns.length - 1)}
          stickedToEndColumns={
            [userAccountColumns[userAccountColumns.length - 1]] as FixedColumnConfig<
              UserAccount,
              ExtraInfo
            >[]
          }
          headerHeight={48}
          rowHeight={56}
          getRowLink={getRowLink}
          renderEmptyList={() => null}
        />
      </View>

      <Space height={32} />
    </Tile>
  );
};

type UserCard = NonNullable<
  GetUserQuery["user"]
>["accountMemberships"]["edges"][number]["node"]["cards"]["edges"][number]["node"];

const userCardColumns: ColumnConfig<UserCard, undefined>[] = [
  {
    id: "cardNumber",
    width: 160,
    title: t("user.details.cards.table.cardNumber"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { cardDesignUrl, cardMaskedNumber } }) => (
      <StartAlignedCell>
        <Image source={{ uri: cardDesignUrl }} style={styles.cardDesign} />
        <Space width={8} />

        <LakeText variant="medium" color={colors.gray[900]}>
          {cardMaskedNumber.slice(-8).replaceAll("X", "•")}
        </LakeText>
      </StartAlignedCell>
    ),
  },
  {
    id: "spendingLimit",
    width: "grow",
    title: t("user.details.cards.table.spendingLimit"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { spending, spendingLimits } }) => {
      const spendingLimit = spendingLimits?.find(({ type }) => type === "AccountHolder");

      if (isNullish(spending) || isNullish(spending.amount) || isNullish(spendingLimit)) {
        return null;
      }
      if (spending.period !== spendingLimit.period) {
        return null;
      }

      return (
        <StartAlignedCell>
          <LakeText
            color={
              Number(spending.amount.value) >= Number(spendingLimit.amount.value)
                ? colors.negative[500]
                : colors.gray[900]
            }
            variant="regular"
          >
            {formatCurrency(Number(spending.amount.value), spending.amount.currency)}
          </LakeText>

          <LakeText variant="regular">{"/"}</LakeText>

          <LakeText color={colors.gray[500]} variant="regular">
            {formatCurrency(Number(spendingLimit.amount.value), spendingLimit.amount.currency)}
          </LakeText>
        </StartAlignedCell>
      );
    },
  },
  {
    id: "type",
    width: 80,
    title: t("user.details.cards.table.type"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item: { type } }) => (
      <EndAlignedCell>
        {match(type)
          .with("SingleUseVirtual", () => (
            <LakeTooltip placement="right" content={t("user.details.cards.type.singleUseVirtual")}>
              <Tag color="darkPink" icon="phone-regular" />
            </LakeTooltip>
          ))
          .with("Virtual", () => (
            <LakeTooltip placement="right" content={t("user.details.cards.type.virtual")}>
              <Tag color="mediumSladeBlue" icon="phone-regular" />
            </LakeTooltip>
          ))
          .with("VirtualAndPhysical", () => (
            <LakeTooltip placement="right" content={t("user.details.cards.type.physical")}>
              <Tag color="shakespear" icon="payment-regular" />
            </LakeTooltip>
          ))
          .exhaustive()}
      </EndAlignedCell>
    ),
  },
  {
    id: "status",
    width: 80,
    title: t("user.details.cards.table.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item: { statusInfo } }) => (
      <EndAlignedCell>
        {match(statusInfo.status)
          .with("Canceled", "Canceling", () => (
            <LakeTooltip placement="right" content={t("user.details.cards.status.canceled")}>
              <Tag icon="dismiss-filled" color="negative" />
            </LakeTooltip>
          ))
          .with("Enabled", "Processing", () => (
            <LakeTooltip placement="right" content={t("user.details.cards.status.enabled")}>
              <Tag icon="checkmark-filled" color="positive" />
            </LakeTooltip>
          ))
          .with("ConsentPending", () => (
            <LakeTooltip placement="right" content={t("user.details.cards.status.consentPending")}>
              <Tag icon="clock-regular" color="shakespear" />
            </LakeTooltip>
          ))
          .exhaustive()}
      </EndAlignedCell>
    ),
  },
];

type UserCardsTileProps = {
  cards: UserCard[];
  nbTotalCards: Option<number>;
  fullName: string;
};

const UserCardsTile = ({ cards, nbTotalCards, fullName }: UserCardsTileProps) => {
  const { projectId, projectEnv } = useProjectInfo();

  const getRowLink = useCallback(
    ({ item }: LinkConfig<UserCard, undefined>) => (
      <Link
        style={styles.rowLink}
        to={Router.CardDetailRoot({ cardId: item.id, projectId, projectEnv })}
      />
    ),
    [projectId, projectEnv],
  );

  if (cards.length === 0) {
    return (
      <EmptyTile
        title={t("user.details.cards.title")}
        link={t("user.details.cards.seeAll")}
        icon="lake-card-filled"
        subTitle={t("user.details.cards.empty.title")}
        description={t("user.details.cards.empty.description")}
      />
    );
  }

  return (
    <Tile style={styles.tileWithList}>
      <Box direction="row" alignItems="center" style={styles.listTitleContainer}>
        <LakeHeading level={2} variant="h3">
          {t("user.details.cards.title")}
        </LakeHeading>

        <Fill minWidth={24} />

        <Link to={Router.CardRoot({ projectId, projectEnv, search: fullName })}>
          <LakeText>
            {nbTotalCards.match({
              None: () => t("user.details.cards.seeAll"),
              Some: nbTotalCards =>
                t("user.details.cards.seeAllWithCount", { count: nbTotalCards }),
            })}
          </LakeText>
        </Link>
      </Box>

      <Space height={32} />

      <PlainListView
        breakpoint={0}
        withoutScroll={true}
        data={cards}
        keyExtractor={item => item.id}
        headerHeight={48}
        rowHeight={56}
        headerStyle={styles.listRow}
        rowStyle={() => styles.listRow}
        groupHeaderHeight={48}
        extraInfo={undefined}
        columns={userCardColumns}
        getRowLink={getRowLink}
      />

      <Space height={32} />
    </Tile>
  );
};

type UserConsent = NonNullable<GetUserQuery["consents"]>["edges"][number]["node"];

const userConsentColumns: ColumnConfig<UserConsent, undefined>[] = [
  {
    id: "purpose",
    width: "grow",
    title: t("user.details.consents.table.purpose"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) => {
      const purpose = getConsentPurpose(item.purpose);
      return <SimpleTitleCell tooltip={{ content: purpose, placement: "right" }} text={purpose} />;
    },
  },
  {
    id: "lastUpdate",
    width: 120,
    title: t("user.details.consents.table.lastUpdate"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { updatedAt } }) => {
      if (isNotNullish(updatedAt)) {
        const diff = getDiffFromNow(dayjs(updatedAt));

        return <SimpleRegularTextCell variant="smallRegular" text={getDiffAgoLabel(diff)} />;
      }

      return null;
    },
  },
  {
    id: "status",
    width: 180,
    title: t("user.details.cards.table.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item: { status } }) => (
      <EndAlignedCell>
        <ConsentStatusTag status={status} />
      </EndAlignedCell>
    ),
  },
];

type UserConsentsTileProps = {
  consents: UserConsent[];
};

const UserConsentsTile = ({ consents }: UserConsentsTileProps) => {
  if (consents.length === 0) {
    return (
      <EmptyTile
        title={t("user.details.consents.title")}
        icon="phone-regular"
        subTitle={t("user.details.consents.empty.title")}
        description={t("user.details.consents.empty.description")}
      />
    );
  }

  return (
    <Tile style={styles.tileWithList}>
      <View style={styles.listTitleContainer}>
        <LakeHeading level={2} variant="h3">
          {t("user.details.consents.title")}
        </LakeHeading>
      </View>

      <Space height={32} />

      <PlainListView
        breakpoint={0}
        withoutScroll={true}
        data={consents}
        keyExtractor={item => item.id}
        headerHeight={48}
        rowHeight={56}
        headerStyle={styles.listRow}
        rowStyle={() => styles.listRow}
        groupHeaderHeight={48}
        extraInfo={undefined}
        columns={userConsentColumns}
      />

      <Space height={32} />
    </Tile>
  );
};

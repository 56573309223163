import { createIntl, createIntlCache } from "@formatjs/intl";
import { Dict } from "@swan-io/boxed";
import { getRifmProps } from "@swan-io/lake/src/utils/rifm";
import { DateFormat } from "@swan-io/shared-business/src/utils/i18n";
import {
  LANGUAGE_FALLBACK,
  getLanguagesHelpers,
} from "@swan-io/shared-business/src/utils/languages";
import dayjs from "dayjs";
import dayJsEnLocale from "dayjs/locale/en";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { ReactElement, ReactNode, cloneElement, isValidElement } from "react";
import translationEN from "../locales/en.json";

// https://day.js.org/docs/en/plugin/plugin
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const supportedLanguages = ["en"] as const;
type SupportedLanguage = (typeof supportedLanguages)[number];

const translationKeys = Dict.keys(translationEN);
export type TranslationKey = (typeof translationKeys)[number];
export type TranslationParams = Record<string, string | number>;

export const isTranslationKey = (value: unknown): value is TranslationKey =>
  typeof value === "string" && translationKeys.includes(value as TranslationKey);

type Locale = {
  language: SupportedLanguage;
  translations: Record<string, string>;
  dayjsLocale: ILocale;
  dateFormat: DateFormat;
  datePlaceholder: string;
  timeFormat: string;
  timePlaceholder: string;
};

const locales: Record<SupportedLanguage, () => Locale> = {
  en: () => ({
    language: "en",
    translations: translationEN,
    dayjsLocale: dayJsEnLocale,
    dateFormat: "DD/MM/YYYY",
    datePlaceholder: "DD/MM/YYYY",
    timeFormat: "HH:mm:ss",
    timePlaceholder: "HH:mm:ss",
  }),
};

export const { getBestLocale, getFirstSupportedLanguage } = getLanguagesHelpers(supportedLanguages);
export const locale = getBestLocale(locales);

// https://day.js.org/docs/en/i18n/loading-into-browser
dayjs.locale(locale.dayjsLocale);

// set lang in html tag for accessibility and screen reader accent
document.documentElement.setAttribute("lang", locale.language);

const intl = createIntl(
  {
    defaultLocale: LANGUAGE_FALLBACK,
    fallbackOnEmptyString: false,
    locale: locale.language,
    messages: locale.translations,
  },
  createIntlCache(),
);

export const t = (key: TranslationKey, params?: TranslationParams) =>
  intl.formatMessage({ id: key, defaultMessage: translationEN[key] }, params).toString();

export const formatCurrency = (value: number, currency: string) =>
  intl.formatNumber(value, { style: "currency", currency });

export const formatCount = (value: number) => intl.formatNumber(value, { style: "decimal" });

export const formatNestedMessage = (
  key: TranslationKey,
  params: Record<string, string | number | ReactElement | ((children: ReactNode) => ReactNode)>,
) => {
  const result = intl.formatMessage(
    { id: key, defaultMessage: translationEN[key] },
    // @ts-expect-error
    params,
  );

  const resultArray: (string | ReactElement)[] = typeof result === "string" ? [result] : result;

  return resultArray.map((item, index) =>
    isValidElement(item) ? cloneElement(item, { key: `t-${key}-${index}` }) : item,
  );
};

export const rifmDateProps = getRifmProps({
  accept: "numeric",
  charMap: { 2: "/", 4: "/" },
  maxLength: 8,
});

export const rifmTimeProps = getRifmProps({
  accept: "numeric",
  charMap: { 2: ":", 4: ":" },
  maxLength: 6,
});

import {
  CellAction,
  CenteredCell,
  ColorPatchCell,
  CopyableRegularTextCell,
  EndAlignedCell,
  LinkCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
  SimpleTitleCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { match } from "ts-pattern";
import { AccountMembershipListFragment, AccountMembershipOrderByInput } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { BooleanTag } from "./BooleanTag";
import { TrackPressable } from "./TrackPressable";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountMembershipOrderByInput) => void;
  sortBy?: AccountMembershipOrderByInput;
  reexecuteQuery: () => void;
};

type Edge = GetEdge<AccountMembershipListFragment>;

type Props = {
  accountMemberships: AccountMembershipListFragment["edges"];
  onEndReached: () => void;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  emptyListTitle: string;
  hasSearchOrFilters: boolean;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns = [
  {
    width: 4,
    id: "color",
    title: "Color",
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <ColorPatchCell color="current" isHovered={isHovered} />
    ),
  },
  {
    width: 240,
    id: "email",
    title: t("accountMembership.email"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { email },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <SimpleTitleCell text={email} />,
  },
];

const columns = [
  {
    width: 200,
    id: "user",
    title: t("accountMembership.user"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { user },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(user) && isNotNullish(user.firstName) && isNotNullish(user.lastName) ? (
        <SimpleRegularTextCell text={`${user.firstName} ${user.lastName}`} />
      ) : null,
  },
  {
    width: 250,
    id: "account",
    title: t("accountMembership.account"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }: ColumnCellConfig<Edge, ExtraInfo>) => {
      if (isNotNullish(account)) {
        const accountId = account.id;

        return (
          <LinkCell
            onPress={() => Router.push("AccountDetailRoot", { projectId, projectEnv, accountId })}
          >
            {`${account.name} - ${account.number}`}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 250,
    id: "accountHolder",
    title: t("accountMembership.accountHolder"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }: ColumnCellConfig<Edge, ExtraInfo>) => {
      if (isNotNullish(account)) {
        const accountHolderId = account.holder.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("HoldersDetailRoot", {
                projectId,
                projectEnv,
                accountHolderId,
              })
            }
          >
            {account.holder.info.name}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 200,
    id: "id",
    title: t("accountMembership.id"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CopyableRegularTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 180,
    id: "legalRepresentative",
    title: t("accountMembership.legalRepresentative"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { legalRepresentative },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        <BooleanTag value={legalRepresentative} />
      </CenteredCell>
    ),
  },
  {
    width: 160,
    id: "canViewAccount",
    title: t("accountMembership.canViewAccount"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { canViewAccount },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        <BooleanTag value={canViewAccount} />
      </CenteredCell>
    ),
  },
  {
    width: 190,
    id: "canInitiatePayments",
    title: t("accountMembership.canInitiatePayments"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { canInitiatePayments },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        <BooleanTag value={canInitiatePayments} />
      </CenteredCell>
    ),
  },
  {
    width: 220,
    id: "canManageBeneficiaries",
    title: t("accountMembership.canManageBeneficiaries"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { canManageBeneficiaries },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        <BooleanTag value={canManageBeneficiaries} />
      </CenteredCell>
    ),
  },
  {
    width: 290,
    id: "canManageAccountMembership",
    title: t("accountMembership.canManageAccountMembership"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { canManageAccountMembership },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        <BooleanTag value={canManageAccountMembership} />
      </CenteredCell>
    ),
  },
  {
    width: 160,
    id: "canManageCards",
    title: t("accountMembership.canManageCards"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { canManageCards },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CenteredCell>
        <BooleanTag value={canManageCards} />
      </CenteredCell>
    ),
  },
  {
    width: 160,
    id: "numberOfCards",
    title: t("accountMembership.numberOfCards"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          cards: { totalCount },
        },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <SimpleRegularTextCell text={String(totalCount)} />,
  },
  {
    width: 200,
    id: "createdAt",
    title: t("accountMembership.createdAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => {
      return (
        <TrackPressable action="Sort memberships by creation date">
          <SimpleHeaderCell
            onPress={direction => {
              extraInfo.onChangeSort?.({ field: "createdAt", direction });
            }}
            sort={
              extraInfo.sortBy?.field === "createdAt"
                ? (extraInfo.sortBy?.direction ?? undefined)
                : undefined
            }
            text={title}
          />
        </TrackPressable>
      );
    },
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <SimpleRegularTextCell
        text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("accountMembership.updatedAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort memberships by update date">
        <SimpleHeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <SimpleRegularTextCell
        text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 160,
    id: "status",
    title: t("accountMembership.status"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <SimpleHeaderCell text={title} justifyContent="flex-end" />
    ),
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <EndAlignedCell>
        {match(status)
          .with("ConsentPending", () => (
            <Tag color="gray">{t("accountMembership.status.consentPending")}</Tag>
          ))
          .with("InvitationSent", () => (
            <Tag color="shakespear">{t("accountMembership.status.invitationSent")}</Tag>
          ))
          .with("BindingUserError", () => (
            <Tag color="negative">{t("accountMembership.status.bindingUserError")}</Tag>
          ))
          .with("Enabled", () => (
            <Tag color="positive">{t("accountMembership.status.enabled")}</Tag>
          ))
          .with("Suspended", () => (
            <Tag color="warning">{t("accountMembership.status.suspended")}</Tag>
          ))
          .with("Disabled", () => <Tag color="gray">{t("accountMembership.status.disabled")}</Tag>)
          .exhaustive()}
      </EndAlignedCell>
    ),
  },
  {
    width: 48,
    id: "actions",
    title: t("common.table.actions"),
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <EndAlignedCell>
        <CellAction>
          <Icon
            name="chevron-right-filled"
            color={isHovered ? colors.gray[700] : colors.gray[200]}
            size={16}
          />
        </CellAction>
      </EndAlignedCell>
    ),
  },
];

export const AccountMembershipList = ({
  accountMemberships,
  onEndReached,
  isLoading,
  perPage,
  extraInfo,
  getRowLink,
  emptyListTitle,
  hasSearchOrFilters,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={accountMemberships}
      stickedToStartColumns={stickedToStartColumns}
      columns={columns}
      stickedToEndColumns={stickedToEndColumns}
      onEndReached={onEndReached}
      headerHeight={48}
      rowHeight={48}
      getRowLink={getRowLink}
      marginHorizontal={negativeSpacings[24]}
      loading={{
        isLoading,
        count: perPage,
      }}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView icon="lake-inbox-empty" title={emptyListTitle} />
        )
      }
    />
  );
};
